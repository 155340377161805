import { useQuery } from '@apollo/client'
import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { localStore } from '@flock/utils'
import {
  Core_Broker,
  LandingSearchBrokersDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useTracking } from '@flock/shared-ui'
import { HOMEPAGE_PATH } from '@flock/shared-ui/src/routeConstants'
import PageWrapper from '../../components/SharedComponents/PageWrapper'
import HomepageHeroSection from '../../components/HomepageComponents/HomepageHeroSection/HomepageHeroSection'
import {
  useRecordPageDuration,
  useScrollDepthTracker,
} from '../../components/utils'

const HomepageFAQSection = React.lazy(
  () => import('../../components/HomepageComponents/HomepageFAQSection')
)

const HomepageCustomerStoriesSection = React.lazy(
  () =>
    import('../../components/HomepageComponents/HomepageCustomerStoriesSection')
)
const HomepageContributorQuoteSection = React.lazy(
  () =>
    import(
      '../../components/HomepageComponents/HomepageContributorQuoteSection'
    )
)
const HomepageProcessSection = React.lazy(
  () => import('../../components/HomepageComponents/HomepageProcessSection')
)

const HomepageFeeCollateralSection = React.lazy(
  () =>
    import('../../components/HomepageComponents/HomepageFeeCollateralSection')
)

const HomepagePortfolioSection = React.lazy(
  () =>
    import(
      '../../components/HomepageComponents/HomepagePortfolioSection/HomepagePortfolioSection'
    )
)

const HomepageOperationsSection = React.lazy(
  () =>
    import(
      '../../components/HomepageComponents/HomepageOperationsSection/HomepageOperationsSection'
    )
)

const HomepageFeatureCard = React.lazy(
  () =>
    import(
      '../../components/SharedComponents/HomepageFeatureCard/HomepageFeatureCard'
    )
)

const HomepageAboutUs = React.lazy(
  () => import('../../components/HomepageComponents/HomepageAboutUs')
)

const HomepagePhoneConsultationCta = React.lazy(
  () =>
    import(
      '../../components/HomepagePhoneConsultationCta/HomepagePhoneConsultationCta'
    )
)

type AgentClientLandingProps = {
  params: {
    agent: string
  }
}

const AgentClientLandingPage = (props: AgentClientLandingProps) => {
  const { params } = props
  const { agent: shortLink } = params
  const { trackPage } = useTracking()
  useRecordPageDuration()
  useScrollDepthTracker()

  // Fetch the broker's information based on the short link path
  const { data, error } = useQuery(LandingSearchBrokersDocument, {
    variables: {
      input: {
        shortLink,
      },
    },
    onCompleted: (queryData: any) => {
      // Inject the retrieved data into local storage.
      const dataArray = queryData.searchBrokers.brokers
      if (dataArray.length > 0) {
        const broker = dataArray[0]
        if (broker?.uuid && localStore?.getItem('disableTracking') !== 'true') {
          localStore.setItem('brokerReferrerUuid', broker.uuid)
          localStore.setItem('brokerShortLink', shortLink)
        }
      } else {
        localStore.removeItem('brokerShortLink')
        localStore.removeItem('brokerReferrerUuid')
        navigate(HOMEPAGE_PATH)
      }
    },
  })

  useEffect(() => {
    if (shortLink) {
      trackPage('agent-client-landing', {
        agent: shortLink,
      })
    }
  }, [shortLink, trackPage])

  if (error) {
    localStore.removeItem('brokerShortLink')
    localStore.removeItem('brokerReferrerUuid')
    navigate(HOMEPAGE_PATH)
  }

  const broker = data?.searchBrokers?.brokers[0] || ({} as Core_Broker)

  const fullName = broker?.firstName
    ? `${broker?.firstName} ${broker?.lastName}`
    : ''

  const { headshotUrl, websiteUrl } = broker
  const isSSR = typeof window === 'undefined'

  return (
    <PageWrapper
      title="Flock | Home"
      trackingName="agents-landing"
      headerBackground="trustBlue.main"
      cobrandingConfig={{
        fullName,
        imageUrl: headshotUrl as string,
        websiteUrl: websiteUrl as string,
      }}
    >
      <HomepageHeroSection />
      {!isSSR && (
        <React.Suspense fallback="">
          <HomepageFeatureCard />
          <HomepageCustomerStoriesSection />
          <HomepageFeeCollateralSection />
          <HomepageAboutUs />
          <HomepageProcessSection />
          <HomepagePortfolioSection />
          <HomepageOperationsSection />
          <HomepageFAQSection />
          <HomepageContributorQuoteSection />
          <HomepagePhoneConsultationCta />
        </React.Suspense>
      )}
    </PageWrapper>
  )
}

export default AgentClientLandingPage
